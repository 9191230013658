<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="8.8" y="0.4" width="53.2" height="70" />
                </defs>
                <clipPath id="SVGID_00000146477951693922363750000003786442284031311008_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g id="그룹_84" style="clip-path:url(#SVGID_00000146477951693922363750000003786442284031311008_);">
                    <path class="fill_dark" d="M61.6,70H9.2V14.8h52.4V70z M11.2,68h48.4V16.8H11.2V68z" />
                    <path class="fill_dark" d="M46.2,24.2h-2V11.6c0-4.9-3.9-8.8-8.8-8.8s-8.8,3.9-8.8,8.8v12.6h-2V11.6c0-6,4.8-10.8,10.8-10.8
    				c6,0,10.8,4.8,10.8,10.8V24.2z" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
