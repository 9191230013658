<template>
    <div class="c_content_container contact">
        <!-- 상단배너 START -->
        <section class="c_section c_banner banner_about">
            <div class="c_inner">
                <div class="img_wrapper about">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_about.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section vision_content">
            <div class="c_inner">
                <!-- 비전 START -->
                <div class="vision">
                    <h1 class="section_title">{{ $t('Vision') }}</h1>
                    <p class="section_text">
                        {{ $t('Vision Txt') }}
                    </p>
                    <ul class="flex_container">
                        <li class="item" v-slidein:reverse="{ percent: '-10%', threshold: 0.1, }">
                            <div class="imgbox">
                                <img src="@/assets/images/about/img_vision_01.png" alt="태도" />
                            </div>
                            <div>
                                <h2 v-textMotion class="text_large">
                                    <span class="textMotion_target" v-textMotion>
                                        Attitude
                                    </span>
                                </h2>
                                <p>{{ $t('Our Attitude') }}</p>
                            </div>
                        </li>
                        <li class="item" v-slidein:reverse="{ percent: '-10%', threshold: 0.1, }">
                            <div class="imgbox">
                                <img src="@/assets/images/about/img_vision_02.png" alt="협력" />
                            </div>
                            <div>
                                <h2 v-textMotion class="text_large">
                                    <span class="textMotion_target" v-textMotion>Cooperation</span>
                                </h2>
                                <p>
                                    {{ $t('Our Cooperation') }}
                                </p>
                            </div>
                        </li>
                        <li class="item" v-slidein:reverse="{ percent: '-10%', threshold: 0.1, }">
                            <div class="imgbox">
                                <img src="@/assets/images/about/img_vision_04.png" alt="책임" />
                            </div>
                            <div>
                                <h2 v-textMotion class="text_large">
                                    <span class="textMotion_target" v-textMotion>Responsibility</span>
                                </h2>
                                <p> {{ $t('Our Responsibility') }}</p>
                            </div>
                        </li>
                        <li class="item" v-slidein:reverse="{ percent: '-10%', threshold: 0.1, }">
                            <div class="imgbox">
                                <img src="@/assets/images/about/img_vision_03.png" alt="동기 부여" />
                            </div>
                            <div>
                                <h2 v-textMotion class="text_large">
                                    <span class="textMotion_target" v-textMotion>Motivation</span>
                                </h2>
                                <p>
                                    {{ $t('Our Motivation') }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 비전 END -->

                <!-- 사업분야 START -->
                <div class="business">
                    <h1 class="section_title">{{ $t('Business Areas') }}</h1>
                    <p class="section_text">
                        {{ $t('Business Areas Txt') }}
                    </p>
                    <ul class="flex_container" v-fadein:reverse.child="{ delay: 200 }">
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconCRM />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>CRM</h3>
                                <p>
                                    {{ $t('Bs Crm Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconCS />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>Custom Solution</h3>
                                <p>
                                    {{ $t('Bs Custom Solution Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconERP />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>ERP</h3>
                                <p>
                                    {{ $t('Bs Erp Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconEC />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>E-commerce</h3>
                                <!-- <h2 v-textMotion>
                                    <span class="textMotion_target" v-textMotion
                                        ><span class="text_deco"
                                            >E-commerce</span
                                        ></span
                                    >
                                </h2> -->
                                <p>
                                    {{ $t('Bs Ecommerce Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconSCM />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>SCM</h3>
                                <!-- <h2 v-textMotion>
                                    <span class="textMotion_target" v-textMotion
                                        ><span class="text_deco"
                                            >SCM</span
                                        ></span
                                    >
                                </h2> -->
                                <p>
                                    {{ $t('Bs Scm Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconAPP />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>App</h3>
                                <!-- <h2 v-textMotion>
                                    <span class="textMotion_target" v-textMotion
                                        ><span class="text_deco"
                                            >App</span
                                        ></span
                                    >
                                </h2> -->
                                <p>
                                    {{ $t('Bs App Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconWA />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>Web Agency</h3>
                                <!-- <h2 v-textMotion>
                                    <span class="textMotion_target" v-textMotion
                                        ><span class="text_deco"
                                            >Web Agency</span
                                        ></span
                                    >
                                </h2> -->
                                <p>
                                    {{ $t('Bs Web Agency Txt') }}
                                </p>
                            </div>
                        </li>
                        <li class="item">
                            <div class="imgbox">
                                <div class="svg_wrap">
                                    <IconOMS />
                                </div>
                            </div>
                            <div class="textbox">
                                <h3>OMS</h3>
                                <!-- <h2 v-textMotion>
                                    <span class="textMotion_target" v-textMotion
                                        ><span class="text_deco"
                                            >OMS</span
                                        ></span
                                    >
                                </h2> -->
                                <p>
                                    {{ $t('Bs Oms Txt') }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 사업분야 END -->
            </div>
        </section>
        <!-- 사업분야 END -->
    </div>
</template>

<script>
import IconCRM from "@/components/SVG/IconVisionCRM.vue";
import IconCS from "@/components/SVG/IconVisionCS.vue";
import IconERP from "@/components/SVG/IconVisionERP.vue";
import IconEC from "@/components/SVG/IconVisionEC.vue";
import IconSCM from "@/components/SVG/IconVisionSCM.vue";
import IconAPP from "@/components/SVG/IconVisionApp.vue";
import IconWA from "@/components/SVG/IconVisionWA.vue";
import IconOMS from "@/components/SVG/IconVisionOMS.vue";
export default {
    components: {
        IconCRM,
        IconCS,
        IconERP,
        IconEC,
        IconSCM,
        IconAPP,
        IconWA,
        IconOMS,
    },
};
</script>

<style scoped src="@/assets/css/about/vision.css"></style>
