<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="0.4" y="0.4" width="70" height="70" />
                </defs>
                <clipPath id="SVGID_00000071534706237928185340000011450524039711809679_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g id="그룹_89" style="clip-path:url(#SVGID_00000071534706237928185340000011450524039711809679_);">
                    <path class="fill_dark"
                        d="M33.2,70C15.4,70,0.9,55.5,0.9,37.7c0-0.6,0.4-1,1-1h31.3c0.3,0,0.5,0.1,0.7,0.3L56,59.1
    				c0.4,0.4,0.4,1,0,1.4C50.1,66.5,41.8,70,33.2,70z M2.9,38.7C3.4,54.9,16.8,68,33.2,68c7.7,0,15.2-3,20.7-8.2L32.8,38.7H2.9z" />
                    <path class="fill_dark" d="M33.2,38.7H1.9c-0.6,0-1-0.4-1-1c0-17.8,14.5-32.3,32.3-32.3c0.6,0,1,0.4,1,1v31.3
    				C34.2,38.3,33.8,38.7,33.2,38.7z M2.9,36.7h29.3V7.4C16.3,7.9,3.4,20.8,2.9,36.7z" />
                    <path class="fill_dark" d="M58.5,64.3L32.2,38V0.9h1c9.6,0,19.1,3.9,26,10.7c14.3,14.3,14.3,37.7,0,52L58.5,64.3z M34.2,37.2l24.3,24.3
    				C64.6,55,68,46.5,68,37.6c0-9.3-3.6-18-10.2-24.6C51.5,6.8,43,3.2,34.2,2.9V37.2z" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
