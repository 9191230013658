<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="8.8" y="0.4" width="53.3" height="70" />
                </defs>
                <clipPath id="SVGID_00000126290659047786118600000007999466194078495147_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g id="그룹_99" style="clip-path:url(#SVGID_00000126290659047786118600000007999466194078495147_);">
                    <path class="fill_dark" d="M58.4,69.8h-46c-1.7,0-3-1.3-3-3V4.1c0-1.7,1.3-3,3-3h46c1.7,0,3,1.3,3,3v62.7
    				C61.4,68.5,60.1,69.8,58.4,69.8z M12.4,3.1c-0.6,0-1,0.4-1,1v62.7c0,0.6,0.4,1,1,1h46c0.6,0,1-0.4,1-1V4.1c0-0.6-0.4-1-1-1H12.4z
    				" />
                    <rect x="10.4" y="14.4" class="fill_dark" width="50" height="2" />
                    <rect x="10.4" y="52.8" class="fill_dark" width="50" height="2" />
                    <rect x="30.4" y="7.8" class="fill_dark" width="10" height="2" />
                    <path id="패스_29" class="fill_dark"
                        d="M37.7,61.1c0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3s1-2.3,2.3-2.3S37.7,59.8,37.7,61.1" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
