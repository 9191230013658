<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="0.4" y="0.5" width="70" height="69.9" />
                </defs>
                <clipPath id="SVGID_00000041989132677394329760000006662207933206240155_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>

                <g id="그룹_106" transform="translate(0 0)"
                    style="clip-path:url(#SVGID_00000041989132677394329760000006662207933206240155_);">
                    <path class="fill_dark"
                        d="M28.5,56.3c-4.4,0-8.8-1-13-3.2C9,49.6,4.2,43.8,2,36.6C-0.1,29.5,0.6,22,4.1,15.5
    					C11.4,2.1,28.2-3,41.7,4.1c3.8,2,7,4.8,9.4,8.2l-1.6,1.1c-2.2-3.1-5.2-5.8-8.6-7.6C28.2-0.8,12.6,4,5.9,16.5
    					c-3.2,6-3.9,13-1.9,19.6c2,6.6,6.5,12,12.5,15.3c12.5,6.6,28.1,1.9,34.9-10.6l1.8,0.9C48.1,51,38.4,56.3,28.5,56.3z" />
                    <rect x="57.5" y="43" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.2109 58.4502)" class="fill_dark"
                        width="2" height="31" />
                    <path class="fill_dark" d="M38.5,42.5C38.5,42.5,38.5,42.5,38.5,42.5c-0.5,0-0.8-0.3-0.9-0.7l-8.7-26.1l-5.3,12.5
    					c-0.2,0.4-0.5,0.6-0.9,0.6h-9.5v-2H22l6-14.3c0.2-0.4,0.6-0.6,1-0.6c0.4,0,0.8,0.3,0.9,0.7l8.7,26.2l5.2-11.3
    					c0.2-0.4,0.5-0.6,0.9-0.6h19v2H45.4l-6,13C39.2,42.3,38.9,42.5,38.5,42.5z" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
