<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="0.4" y="0.4" width="70" height="70" />
                </defs>
                <clipPath id="SVGID_00000019648382986953081580000000264113397066122149_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g id="그룹_70" style="clip-path:url(#SVGID_00000019648382986953081580000000264113397066122149_);">
                    <path class="fill_dark" d="M46.6,25.2H24.2c-0.6,0-1-0.4-1-1c0-6.7,5.5-12.2,12.2-12.2s12.2,5.5,12.2,12.2
    				C47.6,24.8,47.2,25.2,46.6,25.2z M25.2,23.2h20.3C45,18,40.7,14,35.4,14S25.8,18,25.2,23.2z" />
                    <path class="fill_dark" d="M35.4,14c-3.6,0-6.6-3-6.6-6.6s3-6.6,6.6-6.6s6.6,3,6.6,6.6S39,14,35.4,14z M35.4,2.8
    				c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6S40,9.9,40,7.4S37.9,2.8,35.4,2.8z" />
                    <path class="fill_dark" d="M24.2,70H1.8c-0.6,0-1-0.4-1-1c0-6.7,5.5-12.2,12.2-12.2S25.2,62.3,25.2,69C25.2,69.6,24.8,70,24.2,70z
    				 M2.8,68h20.3c-0.5-5.2-4.9-9.2-10.2-9.2S3.4,62.8,2.8,68z" />
                    <path class="fill_dark" d="M13,58.8c-3.6,0-6.6-3-6.6-6.6c0-3.6,3-6.6,6.6-6.6s6.6,3,6.6,6.6C19.6,55.8,16.6,58.8,13,58.8z M13,47.6
    				c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6C17.6,49.7,15.5,47.6,13,47.6z" />
                    <path class="fill_dark" d="M69,70H46.6c-0.6,0-1-0.4-1-1c0-6.7,5.5-12.2,12.2-12.2S70,62.3,70,69C70,69.6,69.6,70,69,70z M47.6,68H68
    				c-0.5-5.2-4.9-9.2-10.2-9.2S48.2,62.8,47.6,68z" />
                    <path class="fill_dark" d="M57.8,58.8c-3.6,0-6.6-3-6.6-6.6c0-3.6,3-6.6,6.6-6.6c3.6,0,6.6,3,6.6,6.6C64.4,55.8,61.4,58.8,57.8,58.8z
    				 M57.8,47.6c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6C62.4,49.7,60.3,47.6,57.8,47.6z" />
                    <rect x="34.4" y="29.8" class="fill_dark" width="2" height="11.2" />
                    <rect x="39.2" y="38.2" transform="matrix(0.5 -0.866 0.866 0.5 -17.8063 56.7595)" class="fill_dark" width="2"
                        height="11.2" />
                    <rect x="25" y="42.8" transform="matrix(0.8661 -0.4999 0.4999 0.8661 -17.8049 21.1386)" class="fill_dark"
                        width="11.2" height="2" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
