<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="0.4" y="0.4" width="70" height="70" />
                </defs>
                <clipPath id="SVGID_00000076593657714404919030000016150982467971027866_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                </clipPath>
                <g id="그룹_82" style="clip-path:url(#SVGID_00000076593657714404919030000016150982467971027866_);">
                    <path class="fill_dark" d="M11.8,60.1c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.6-0.3-0.9L13,47.8c0-0.2,0.1-0.4,0.3-0.5l30.5-30.6
    				c1.6-1.6,4.1-1.6,5.7,0l4.7,4.7c0.8,0.7,1.2,1.7,1.2,2.7c0,1.1-0.4,2.1-1.2,2.9L23.6,57.6c-0.1,0.1-0.3,0.2-0.5,0.3L12,60.1
    				C11.9,60.1,11.9,60.1,11.8,60.1z M14.9,48.5l-1.9,9.3l9.3-1.8l30.4-30.4c0.4-0.4,0.6-0.9,0.6-1.5c0-0.5-0.2-1-0.6-1.3
    				c0,0,0,0,0,0l-4.7-4.7c-0.8-0.8-2.1-0.8-2.9,0L14.9,48.5z" />
                    <rect x="44.7" y="19" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.4847 39.6722)" class="fill_dark"
                        width="2" height="12.6" />
                    <path class="fill_dark" d="M35.4,70c-9.2,0-17.9-3.6-24.4-10.1l1.4-1.4c6.1,6.1,14.3,9.5,23,9.5c17.9,0,32.5-14.6,32.5-32.5
    				C67.9,17.6,53.3,3,35.4,3C17.5,3,3,17.5,3,35.4H1C1,16.4,16.4,1,35.4,1c19,0,34.5,15.5,34.5,34.5S54.4,70,35.4,70z" />
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
