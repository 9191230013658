<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g id="그룹_102">
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="0.4" y="3.2" width="70" height="64.4" />
                    </defs>
                    <clipPath id="SVGID_00000046317345631822220060000003013029308088713350_">
                        <use xlink:href="#SVGID_1_" style="overflow:visible;" />
                    </clipPath>
                    <g id="그룹_101" style="clip-path:url(#SVGID_00000046317345631822220060000003013029308088713350_);">
                        <path class="fill_dark"
                            d="M69,61.6h-5.6v-2H68v-14l-3.9-3.9c-0.1-0.1-0.2-0.2-0.2-0.3L58.5,28H44.8v31.6h7.4v2h-8.4c-0.6,0-1-0.4-1-1
    					V27c0-0.6,0.4-1,1-1h15.4c0.4,0,0.8,0.2,0.9,0.6l5.5,13.8l4.1,4.1c0.2,0.2,0.3,0.4,0.3,0.7v15.4C70,61.2,69.6,61.6,69,61.6z" />
                        <path class="fill_dark" d="M43.8,61.6H21.4v-2h21.4V28H5.6v31.6h4.6v2H4.6c-0.6,0-1-0.4-1-1V27c0-0.6,0.4-1,1-1h39.2c0.6,0,1,0.4,1,1
    					v33.6C44.8,61.2,44.4,61.6,43.8,61.6z" />
                    </g>
                </g>
            </g>
        </g>
        <path id="패스_35" class="fill_dark" d="M24.2,27.2v33.5" />
        <g id="그룹_104">
            <g>
                <g>
                    <defs>
                        <rect id="SVGID_00000039117779273382999010000013989910807225073072_" x="0.4" y="3.2" width="70"
                            height="64.4" />
                    </defs>
                    <clipPath id="SVGID_00000044894696436646995030000005533883236991417260_">
                        <use xlink:href="#SVGID_00000039117779273382999010000013989910807225073072_"
                            style="overflow:visible;" />
                    </clipPath>
                    <g id="그룹_103" style="clip-path:url(#SVGID_00000044894696436646995030000005533883236991417260_);">
                        <path class="fill_dark" d="M46.6,28H1.8c-0.6,0-1-0.4-1-1v-8.4c0-0.6,0.4-1,1-1h44.8c0.6,0,1,0.4,1,1V27C47.6,27.6,47.2,28,46.6,28z
    					 M2.8,26h42.8v-6.4H2.8V26z" />
                        <path class="fill_dark" d="M57.8,67.2c-3.6,0-6.6-3-6.6-6.6c0-3.6,3-6.6,6.6-6.6c3.6,0,6.6,3,6.6,6.6C64.4,64.2,61.4,67.2,57.8,67.2z
    					 M57.8,56c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6C62.4,58.1,60.3,56,57.8,56z" />
                        <path class="fill_dark" d="M15.8,67.2c-3.6,0-6.6-3-6.6-6.6c0-3.6,3-6.6,6.6-6.6s6.6,3,6.6,6.6C22.4,64.2,19.4,67.2,15.8,67.2z
    					 M15.8,56c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6C20.4,58.1,18.3,56,15.8,56z" />
                        <path class="fill_dark" d="M64.8,42H53.6c-0.6,0-1-0.4-1-1V27h2v13h10.2V42z" />
                        <path class="fill_dark" d="M24.2,19.6h-7c-4.5,0-8-3.5-8-8s3.5-8,8-8s8,3.5,8,8v7C25.2,19.2,24.8,19.6,24.2,19.6z M17.2,5.6
    					c-3.4,0-6,2.6-6,6s2.6,6,6,6h6v-6C23.2,8.2,20.6,5.6,17.2,5.6z" />
                        <path class="fill_dark" d="M31.2,19.6h-7c-0.6,0-1-0.4-1-1v-7c0-4.5,3.5-8,8-8s8,3.5,8,8S35.7,19.6,31.2,19.6z M25.2,17.6h6
    					c3.4,0,6-2.6,6-6s-2.6-6-6-6s-6,2.6-6,6V17.6z" />
                    </g>
                </g>
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
