<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <defs>
                <rect id="SVGID_1_" x="0.4" y="3.2" width="70" height="64.4" />
            </defs>
            <clipPath id="SVGID_00000155846423405950794890000006031914631963729802_">
                <use xlink:href="#SVGID_1_" style="overflow:visible;" />
            </clipPath>
            <g id="그룹_97" style="clip-path:url(#SVGID_00000155846423405950794890000006031914631963729802_);">
                <path class="fill_dark" d="M31.6,67.2H2.8c-1.2,0-2-0.8-2-2V28c0-1,1-2,2-2h28.8c1.2,0,2,0.8,2,2v37.2C33.6,66.4,32.8,67.2,31.6,67.2z
    				 M2.9,28L2.8,65.2h28.8V28H2.9z" />
                <rect x="1.8" y="33" class="fill_dark" width="30.8" height="2" />
                <rect x="1.8" y="58.2" class="fill_dark" width="30.8" height="2" />
                <path class="fill_dark" d="M68,67.2H50.4c-1.2,0-2-0.8-2-2V35c0-1,1-2,2-2H68c1.2,0,2,0.8,2,2v30.2C70,66.4,69.2,67.2,68,67.2z
    				 M50.5,35l-0.1,30.2H68V35H50.5z" />
                <rect x="49.4" y="38.6" class="fill_dark" width="19.6" height="2" />
                <rect x="49.4" y="59.6" class="fill_dark" width="19.6" height="2" />
                <path class="fill_dark" d="M64.4,27h-2V7.4c0-0.9-0.9-1.8-1.8-1.8H10.2c-0.9,0-1.8,0.9-1.8,1.8V20h-2V7.4c0-2.1,1.7-3.8,3.8-3.8h50.4
    				c2.1,0,3.8,1.7,3.8,3.8V27z" />
                <rect x="36.8" y="45.6" class="fill_dark" width="8.4" height="2" />
            </g>
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
